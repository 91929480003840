import axiosInstance from "../axiosInstance";
import { Company, Products } from "../models/Company";

export const getCompanies = async (eventAlias: string): Promise<Company[]> => {
  const response = await axiosInstance.get("/companies", {
    params: { eventAlias },
  });
  return response.data;
};

export const getCompany = async (companyId: string): Promise<Company> => {
  const response = await axiosInstance.get(`/companies/${companyId}`);
  return response.data;
};

export const getCompanyProducts = async (
  companyExternalId: string
): Promise<Products> => {
  const response = await axiosInstance.get(
    `/companies/${companyExternalId}/products`
  );
  return response.data;
};
